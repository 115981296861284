<template>
  <div>
    <span class="primary--text">
      Input a patient identifier for your records.<br />
      A case number will be generated later.
    </span>
    <v-form ref="form" @submit.prevent="handleContinue" class="mt-2">
      <v-text-field
        v-model="orderDetails.patient_reference"
        outlined
        dense
        style="width: 400px; flex-grow: 0"
        :rules="[(v) => !!v || 'Required']"
        label="Patient ID"
        class="rounded-10"
      />
    </v-form>

    <v-btn class="btn-primary" @click="handleContinue"> Continue </v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "PatientId",

  computed: {
    ...mapGetters(["orderDetails"])
  },

  methods: {
    handleContinue() {
      if (!this.$refs.form.validate()) return

      this.$emit("next")
    }
  }
}
</script>
