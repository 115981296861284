import Vue from "vue"
import Vuex from "vuex"

import client from "@/lib/ApiClient"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    orderUid: null,
    orderSessionUid: null,
    orderDetails: {
      kit_box_id: null,
      patient_reference: null,
      // caseType: null,
      // responses: null,
      // client_uid: null,
      nc_session_id: null,
      session_type: null,
      assigned: true
      // submitted_at: null
    },
    questions: null,
    existingPatient: null,

    additionalFiles: [],
    settings: null,
    accessToken: null,
    country: null,
    pageState: [{ cols: 7, component: "ConnectBranding" }],
    client: null,
    transition: null,

    retrying: false,
    retryCount: 0,
    faceCount: null,
    uploadedScans: [],
    addScansResponse: null,
    selectGaugesResponse: null,
    validateScansResponse: null,

    errorMessage: null
  },
  getters: {
    orderUid: (state) => state.orderUid,
    orderSessionUid: (state) => state.orderSessionUid,
    orderDetails: (state) => state.orderDetails,
    questions: (state) => state.questions,
    existingPatient: (state) => state.existingPatient,
    additionalFiles: (state) => state.additionalFiles,
    settings: (state) => state.settings,
    accessToken: (state) => state.accessToken,
    country: (state) => state.country,
    pageState: (state) => state.pageState,
    scanValidationScore: (state) => state.scanValidationScore,
    client: (state) => state.client,
    transition: (state) => state.transition,
    retrying: (state) => state.retrying,
    retryCount: (state) => state.retryCount,
    faceCount: (state) => state.faceCount,
    uploadedScans: (state) => state.uploadedScans,
    addScansResponse: (state) => state.addScansResponse,
    validateScansResponse: (state) => state.validateScansResponse,
    validationSuccess: (state) => {
      if (!state.validateScansResponse) return
      const scoreKey =
        state.orderDetails.session_type === "immediate"
          ? "scoreImmediate"
          : "scoreRestorative"
      return state.validateScansResponse.scanAnalysis[scoreKey] >= 4
    },
    errorMessage: (state) => state.errorMessage
  },
  mutations: {
    SET_ORDER_UID(state, payload) {
      state.orderUid = payload
    },
    SET_ORDER_SESSION_UID(state, payload) {
      state.orderSessionUid = payload
    },
    SET_ORDER_DETAILS(state, payload) {
      state.orderDetails = payload
    },
    SET_QUESTIONS(state, payload) {
      state.questions = payload
    },
    SET_EXISTING_PATIENT(state, payload) {
      state.existingPatient = payload
    },
    SET_ADDITIONAL_FILES(state, payload) {
      state.additionalFiles = payload
    },
    SET_SETTINGS(state, payload) {
      state.settings = payload
    },
    SET_ACCESS_TOKEN(state, payload) {
      state.accessToken = payload
    },
    SET_COUNTRY(state, payload) {
      state.country = payload
      client.oft.setCountry(payload)
    },
    SET_PAGE_STATE(state, payload) {
      state.pageState = payload
    },
    SET_SCAN_VALIDATION_SCORE(state, payload) {
      state.scanValidationScore = payload
    },
    SET_CLIENT(state, payload) {
      state.client = payload
    },
    SET_TRANSITION(state, payload) {
      state.transition = payload
    },
    SET_UPLOADED_SCANS(state, payload) {
      state.uploadedScans = payload
    },
    SET_ADD_SCANS_RESPONSE(state, payload) {
      state.addScansResponse = payload
    },
    SET_VALIDATE_SCANS_RESPONSE(state, payload) {
      state.validateScansResponse = payload
    },
    SET_MODEL_SCAN(state, payload) {
      state.modelScan = payload
    },
    SET_RETRYING(state, payload) {
      state.retrying = payload
    },
    SET_RETRY_COUNT(state, payload) {
      state.retryCount = payload
    },
    SET_FACE_COUNT(state, payload) {
      state.faceCount = payload
    },
    SET_ERROR_MESSAGE(state, payload) {
      state.errorMessage = payload
    }
  },
  actions: {
    setOrderUid({ commit }, payload) {
      commit("SET_ORDER_UID", payload)
    },
    setOrderSessionUid({ commit }, payload) {
      commit("SET_ORDER_SESSION_UID", payload)
    },
    setOrderDetails({ commit }, payload) {
      commit("SET_ORDER_DETAILS", payload)
    },
    setQuestions({ commit }, payload) {
      commit("SET_QUESTIONS", payload)
    },
    setExistingPatient({ commit }, payload) {
      commit("SET_EXISTING_PATIENT", payload)
    },
    setAdditionalFiles({ commit }, payload) {
      commit("SET_ADDITIONAL_FILES", payload)
    },
    setSettings({ commit }, payload) {
      commit("SET_SETTINGS", payload)
    },
    setAccessToken({ commit }, payload) {
      commit("SET_ACCESS_TOKEN", payload)
    },
    setCountry({ commit }, payload) {
      commit("SET_COUNTRY", payload)
    },
    setPageState({ commit }, payload) {
      commit("SET_PAGE_STATE", payload)
    },
    setScanValidationScore({ commit }, payload) {
      commit("SET_SCAN_VALIDATION_SCORE", payload)
    },
    setClient({ commit }, payload) {
      commit("SET_CLIENT", payload)
    },
    setTransition({ commit }, payload) {
      commit("SET_TRANSITION", payload)
    },
    setAddScansResponse({ commit }, payload) {
      commit("SET_ADD_SCANS_RESPONSE", payload)
    },
    setUploadedScans({ commit }, payload) {
      commit("SET_UPLOADED_SCANS", payload)
    },
    setValidateScansResponse({ commit }, payload) {
      commit("SET_VALIDATE_SCANS_RESPONSE", payload)
    },
    setModelScan({ commit }, payload) {
      commit("SET_MODEL_SCAN", payload)
    },
    setRetrying({ commit }, payload) {
      commit("SET_RETRYING", payload)
    },
    setRetryCount({ commit }, payload) {
      commit("SET_RETRY_COUNT", payload)
    },
    setFaceCount({ commit }, payload) {
      commit("SET_FACE_COUNT", payload)
    },
    setErrorMessage({ commit }, payload) {
      commit("SET_ERROR_MESSAGE", payload)
    },
    restartSession({ dispatch, getters }) {
      const orderDetails = {
        ...getters.orderDetails,
        nc_session_id: null
      }

      dispatch("setOrderDetails", orderDetails)
      dispatch("setQuestions", null)
      dispatch("setExistingPatient", false)
      dispatch("setUploadedScans", [])
      dispatch("setAdditionalFiles", [])
      dispatch("setAddScansResponse", null)
      dispatch("setFaceCount", null)
      dispatch("setRetryCount", 0)
      dispatch("setRetrying", false)
      dispatch("setValidateScansResponse", null)
      dispatch("setPageState", [{ cols: 7, component: "ConnectBranding" }])
    },
    newSession({ dispatch, getters }) {
      dispatch("restartSession")
      dispatch("setOrderUid", null)
      dispatch("setOrderSessionUid", null)
      const orderDetails = {
        kit_box_id: null,
        patient_reference: null,
        caseType: null,
        session_type: null,
        nc_session_id: null,
        assigned: true
      }
      if (getters.settings.manage_clients)
        orderDetails.client_uid = getters.client.uid
      dispatch("setOrderDetails", orderDetails)
      dispatch("setPageState", [{ cols: 7, component: "ConnectBranding" }])
    }
  },
  modules: {}
})
