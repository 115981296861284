<template>
  <div>
    <span class="primary--text">
      To start a new order for a new patient, select 'New Patient'. To start
      another session for an existing patient, select 'Existing Patient'.
    </span>
    <v-card flat>
      <v-card-text>
        <v-btn-toggle v-model="selection" color="primary" class="rounded-10">
          <v-btn value="new">
            <v-icon v-if="selection === 'new'" color="primary" class="mr-2">
              mdi-check-circle
            </v-icon>
            <v-icon v-if="selection !== 'new'" color="secondary" class="mr-2">
              mdi-circle
            </v-icon>
            New Patient
          </v-btn>
          <v-btn value="existing">
            <v-icon
              v-if="selection === 'existing'"
              color="primary"
              class="mr-2"
            >
              mdi-check-circle
            </v-icon>
            <v-icon
              v-if="selection !== 'existing'"
              color="secondary"
              class="mr-2"
            >
              mdi-circle </v-icon
            >Existing Patient</v-btn
          >
        </v-btn-toggle>
      </v-card-text>
      <v-card-text>
        <v-btn
          class="btn-primary rounded-10"
          @click="handleContinue"
          :disabled="!selection"
        >
          Continue
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "NewOrExistingPatient",

  data() {
    return {
      selection: null
    }
  },

  watch: {
    async selection(newVal) {
      this.setExistingPatient(newVal === "existing")
    }
  },

  methods: {
    ...mapActions(["setExistingPatient"]),

    async handleContinue() {
      if (!this.selection) return

      this.$emit("next")
    }
  }
}
</script>
