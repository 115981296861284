import GatewayAPI from "./GatewayAPI"
import InstanceAPI from "./InstanceAPI"
import OftAPI from "./OftApi"

class HttpClient {
  constructor() {
    this.gateway = new GatewayAPI()
    this.instance = new InstanceAPI()
    this.oft = new OftAPI()
    this.instanceUrl = null
    this.tenantUid = null
  }

  setTenantUid(uid) {
    this.gateway.setTenantUid(uid)
    this.instance.setTenantUid(uid)
    this.tenantUid = uid
  }

  setInstanceUrl(url) {
    this.instance.setInstanceUrl(url)
    this.instanceUrl = url
  }
}

var client = new HttpClient()

export default client

export function fetchURLParam(param) {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  if (urlParams.has(param)) {
    return urlParams.get(param)
  }
  return false
}
