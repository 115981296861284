<template>
  <div>
    <span class="primary--text"> Select from the options below: </span>
    <v-btn
      :color="caseTypeIsImmediate === false ? 'card' : 'off'"
      width="100%"
      class="px-0 py-11 d-flex align-center elevation-0 mt-2 mb-3 rounded-10"
      style="text-transform: none"
      @click="caseTypeIsImmediate = false"
    >
      <v-icon size="80" class="ml-5 mr-1">$singlearch</v-icon>
      <v-card-text class="text-left">
        <span class="primary--text font-weight-medium text-body-1">
          Restorative (Final) Case
        </span>
        <br />
        <span> For all cases requiring a permanent prosthesis. </span>
      </v-card-text>
    </v-btn>
    <v-btn
      :color="caseTypeIsImmediate ? 'card' : 'off'"
      width="100%"
      class="px-0 py-11 d-flex align-center elevation-0 rounded-10"
      style="text-transform: none"
      @click="caseTypeIsImmediate = true"
    >
      <v-icon size="55" class="ml-7 mr-5">$surgicaltool</v-icon>
      <v-card-text class="text-left">
        <span class="primary--text font-weight-medium text-body-1">
          Surgical (Immediate) Case
        </span>
        <br />
        <span> For cases only intended to have a provisional prosthesis. </span>
      </v-card-text>
    </v-btn>
    <v-fade-transition>
      <v-btn
        v-if="caseTypeIsImmediate !== null"
        class="btn-primary mt-3"
        @click="handleContinue"
        :loading="loading"
      >
        Continue
      </v-btn>
    </v-fade-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
  name: "CaseType",

  data() {
    return {
      caseTypeIsImmediate: null,
      loading: false
    }
  },

  watch: {
    caseTypeIsImmediate(newVal) {
      this.setOrderDetails({
        ...this.orderDetails,
        session_type: newVal ? "immediate" : "definitive"
      })
    }
  },

  computed: {
    ...mapGetters(["orderDetails"])
  },

  methods: {
    ...mapActions(["setOrderDetails"]),

    async handleContinue() {
      if (this.caseTypeIsImmediate === null) return

      this.$emit("next")
    }
  }
}
</script>
