<template>
  <v-card flat class="my-5">
    <v-row>
      <v-row v-if="loading" class="my-5">
        <v-col class="text-center">
          <LoadingSpinner />
        </v-col>
      </v-row>
      <v-col cols="12" class="text-center" v-if="!loading && kits.length === 0">
        You currently have no scan gauge kits associated to your account.
      </v-col>
      <v-col cols="3" v-for="(kit, index) in kits" :key="index">
        <v-card
          flat
          class="text-center rounded-10 mx-auto"
          :class="{ 'btn-primary': kit.kit_box_id === scanGaugeKit }"
          @click="scanGaugeKit = kit.kit_box_id"
        >
          <v-img contain :src="require('@/assets/sgk-gen2.png')" />
          <small>
            {{ kit.kit_box_id }}
          </small>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue"

export default {
  name: "ScanGaugeKitSelector",

  props: ["value", "kits", "loading"],

  components: { LoadingSpinner },

  computed: {
    scanGaugeKit: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
