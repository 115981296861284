<template>
  <v-card class="pb-4 rounded-10">
    <v-card-text>
      <v-stepper
        v-model="currentStepIndex"
        vertical
        height="100%"
        flat
        class="pa-0"
      >
        <template v-for="(item, index) in items">
          <v-stepper-step
            :key="`step${index}`"
            :step="index + 1"
            :complete="completedSteps.includes(index + 1)"
            :color="completedSteps.includes(index + 1) ? 'success' : 'primary'"
            edit-icon="mdi-check"
            :editable="editable(index)"
            class="title-font pb-5"
            style="font-size: 17px"
          >
            {{ item.title }}
          </v-stepper-step>
          <v-stepper-content
            :step="index + 1"
            :key="`content${index}`"
            class="pt-0"
          >
            <component
              :is="item.component"
              @next="currentStepIndex++"
              @step="setStep"
            />
          </v-stepper-content>
        </template>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import ChooseKit from "./MainCard/ChooseKit.vue"
import KitAuthentication from "./MainCard/KitAuthentication.vue"
import ClientName from "./MainCard/ClientName.vue"
import PatientId from "./MainCard/PatientId.vue"
import CaseType from "./MainCard/CaseType.vue"
import UploadScans from "./MainCard/UploadScans.vue"
import ScanAnalysis from "./MainCard/ScanAnalysis.vue"
import UploadFiles from "./MainCard/UploadFiles.vue"
import RxForm from "./MainCard/RxForm.vue"
import NewOrExistingPatient from "./MainCard/NewOrExistingPatient.vue"

export default {
  name: "MainCard",

  components: {
    KitAuthentication,
    ChooseKit,
    ClientName,
    PatientId,
    CaseType,
    UploadScans,
    ScanAnalysis,
    UploadFiles,
    RxForm,
    NewOrExistingPatient
  },

  data() {
    return {
      currentStepIndex: 1,
      completedSteps: []
    }
  },

  watch: {
    currentStepIndex(step) {
      const openStep = this.items[step - 1]

      if (!this.completedSteps.includes(step - 1) && step !== 1)
        this.completedSteps.push(step - 1)

      if (openStep.component === RxForm) {
        return this.setPageState([
          { cols: 2 },
          { cols: 5, component: "OrderForm" }
        ])
      }

      if (openStep.component === ScanAnalysis) {
        this.setTransition(true)
        var component = this.validateScansResponse
          ? "ValidatedDiv"
          : "SelectionDivs"
        return this.setPageState([{ cols: 7, component }])
      }

      return this.setPageState([{ cols: 7, component: "ConnectBranding" }])
    }
  },

  methods: {
    ...mapActions(["setPageState", "setTransition"]),

    setStep(componentName) {
      const stepIndex = this.items.findIndex(
        (i) => i.component.name === componentName
      )
      this.currentStepIndex = stepIndex + 1
    },

    editable(index) {
      let openStep = this.items[this.currentStepIndex - 1]

      if (
        openStep.component.name === "PatientId" ||
        openStep.component.name === "NewOrExistingPatient"
      ) {
        if (!this.orderDetails.patient_reference) return false
      }
      if (
        this.completedSteps.includes(index + 1) ||
        (index === this.completedSteps.length &&
          this.currentStepIndex !== index + 1)
      ) {
        return true
      }

      return false
    }
  },

  computed: {
    ...mapGetters(["settings", "orderDetails", "validateScansResponse"]),

    items() {
      const components = []

      if (this.settings.manage_clients) {
        components.push({
          title: "CHOOSE SCAN GAUGE KIT",
          component: ChooseKit
        })
      } else {
        components.push(
          {
            title: "ENTER SCAN GAUGE KIT SERIAL NUMBER",
            component: KitAuthentication
          },
          {
            title: "YOUR DETAILS",
            component: ClientName
          }
        )
      }
      if (this.settings.immediate_workflow) {
        components.push({
          title: "CHOOSE NEW OR EXISTING PATIENT",
          component: NewOrExistingPatient
        })
      }

      components.push({
        title: "PATIENT ID",
        component: PatientId
      })

      if (this.settings.immediate_workflow)
        components.push({ title: "CHOOSE CASE TYPE", component: CaseType })

      components.push(
        { title: "UPLOAD GAUGE SCANS", component: UploadScans },
        { title: "GAUGE SCAN ANALYSIS", component: ScanAnalysis },
        { title: "UPLOAD ADDITIONAL FILES", component: UploadFiles },
        { title: "RX FORM", component: RxForm }
      )

      return components
    }
  }
}
</script>
