<template>
  <v-app fill-height>
    <SystemBar />
    <AppBar />
    <v-main class="gradient-bg">
      <v-container fluid class="fill-height">
        <LoadingSpinner v-if="loading" class="mx-auto mb-auto" />
        <div class="mt-10 mx-auto mb-auto" v-if="invalidSlug">
          <v-alert color="error" dark width="300px" class="text-center mx-auto">
            <v-icon class="mr-2">mdi-alert</v-icon>
            Invalid Ordering URL
          </v-alert>
          <v-row>
            <v-col class="text-center font-weight-medium mt-3">
              Please check the URL you have entered and try again.
            </v-col>
          </v-row>
        </div>

        <div
          class="mt-10 mx-auto mb-auto"
          v-if="unauthorisedClient && !redirecting"
        >
          <v-fade-transition>
            <v-row justify="center" class="mt-5">
              <v-col>
                <UnauthorisedClient />
              </v-col>
            </v-row>
          </v-fade-transition>
        </div>

        <router-view v-if="!loading && !unauthorisedClient && !invalidSlug" />
      </v-container>
    </v-main>
  </v-app>
</template>

<style type="scss">
html {
  overflow-y: auto;
}
.gradient-bg {
  background: linear-gradient(
    v-bind(settings?.colours.backgroundDir),
    v-bind(settings?.colours.background1) 0%,
    v-bind(settings?.colours.background1) 11%,
    v-bind(settings?.colours.background2) 100%
  );
}
.card-bg {
  background: linear-gradient(
    to bottom,
    v-bind(settings?.colours.card2) 0%,
    v-bind(settings?.colours.card) 100%
  );
}
.title-font {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.btn-primary {
  background: linear-gradient(
    90deg,
    v-bind(settings?.colours.primary),
    v-bind(settings?.colours.primary2)
  );
  color: white !important;
  border-radius: 10px !important;
  box-shadow: none;
}
.btn-success {
  background: linear-gradient(
    90deg,
    v-bind(settings?.colours.success),
    v-bind(settings?.colours.success2)
  );
  color: white !important;
  border-radius: 10px !important;
  box-shadow: none;
}
.rounded-10 {
  border-radius: 10px !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex"
import AppBar from "@/components/App/AppBar"
import SystemBar from "@/components/App/SystemBar"
import apiClient from "@/lib/ApiClient"
import UnauthorisedClient from "@/components/UnauthorisedClient.vue"
import LoadingSpinner from "./components/shared/LoadingSpinner.vue"
import { defaultColours } from "./utils"

export default {
  name: "App",

  data() {
    return {
      loading: true,
      redirecting: false,
      invalidSlug: false,
      slug: null,
      unauthorisedClient: false
    }
  },

  components: {
    AppBar,
    SystemBar,
    UnauthorisedClient,
    LoadingSpinner
  },

  computed: {
    ...mapGetters([
      "accessToken",
      "client",
      "orderDetails",
      "settings",
      "primary",
      "country"
    ])
  },

  methods: {
    ...mapActions([
      "setSettings",
      "setClient",
      "setAccessToken",
      "setOrderDetails",
      "setCountry"
    ]),

    redirectToID() {
      this.redirecting = true
      this.$cookies.set("tenantSlug", this.slug)
      window.location = "/authorize"
    },

    applyTheme() {
      const colours = { ...this.settings.colours }
      for (let key in colours) {
        if (colours[key].length === 9 && /^.{7}[fF]{2}$/.test(colours[key]))
          colours[key] = colours[key].slice(0, 7)
        if (colours[key].length === 7)
          this.$vuetify.theme.themes.light[key] = colours[key]
      }
    },

    setInvalidSlug() {
      this.invalidSlug = true
      this.setSettings({ colours: defaultColours })
      this.applyTheme()
      this.loading = false
    }
  },

  async beforeMount() {
    const path = this.$route.path.replace("/", "")
    this.slug = this.$cookies.get("tenantSlug")
    if (this.slug && path !== this.slug)
      this.$router.push({ name: "homeWithSlug", params: { slug: this.slug } })

    if (!this.slug) this.slug = path

    if (!this.slug) return this.setInvalidSlug()
    this.$cookies.remove("tenantSlug")

    try {
      const bffResponse = await this.$axiosAuth.get(`/me?slug=${this.slug}`)
      const {
        settings,
        accessToken,
        client,
        tenantUid,
        instanceUrl,
        instanceCountryCode,
        invalidSlug
      } = bffResponse.data

      if (invalidSlug) return this.setInvalidSlug()

      document.title = settings.tenant_name

      apiClient.setTenantUid(tenantUid)
      apiClient.setInstanceUrl(instanceUrl)

      this.setAccessToken(accessToken)
      this.$axios.defaults.headers.Authorization = `Bearer ${accessToken}`

      this.setCountry(instanceCountryCode)

      if (client) {
        this.setClient(client)
        this.setOrderDetails({
          ...this.orderDetails,
          client_uid: client.uid
        })
      }
      if (accessToken && client === null) {
        this.unauthorisedClient = true
      }

      if (!settings?.colours) settings.colours = { ...defaultColours }
      this.setSettings(settings)
      this.applyTheme()
    } catch (error) {
      console.log(error)
      return this.redirectToID()
    }

    this.loading = false
  }
}
</script>
