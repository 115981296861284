<template>
  <v-card flat>
    <v-card-title class="title-font pb-1 pt-0" style="font-size: medium">
      <v-icon :color="file ? 'success' : 'error'" class="ml-n3 mr-2" small>
        {{ file ? "mdi-check-circle" : "mdi-close-circle" }}
      </v-icon>
      {{ categoryTitle }}
      <small v-if="categorySubtitle" class="ml-3">
        {{ categorySubtitle }}
      </small>
    </v-card-title>
    <v-card-subtitle class="error--text ml-3" v-if="error">
      Required
    </v-card-subtitle>
    <v-card-text>
      <v-row v-if="fileUrl">
        <v-col cols="12">
          <ModelViewer
            :filename="file.name"
            :url="fileUrl"
            :height="264"
            :width="300"
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row v-if="!file">
        <v-col cols="12" class="px-0">
          <FileUploader
            v-model="file"
            text="Upload"
            :fileExtension="permittedExtensions"
            :category="category"
            :disabled="disabled"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ModelViewer from "@/components/HomePage/MainCard/UploadScans/ScanUploader/ModelViewer.vue"
import FileUploader from "@/components/HomePage/MainCard/UploadScans/ScanUploader/FileUploader.vue"

export default {
  name: "NewOrderScanUploader",

  props: ["value", "categoryTitle", "category", "categorySubtitle", "disabled"],

  inject: ["form"],

  components: { FileUploader, ModelViewer },

  data() {
    return {
      fileUrl: null,
      error: null,
      valid: true
    }
  },

  watch: {
    file(value) {
      if (value) {
        this.fileUrl = URL.createObjectURL(value)
      }
      if (!value) this.fileUrl = null
    }
  },

  computed: {
    file: {
      get() {
        return this.value
      },

      set(value) {
        if (value) {
          this.error = null
        }
        this.$emit("input", value)
      }
    },

    permittedExtensions() {
      return ["stl", "ply"]
    }
  },

  methods: {
    validate() {
      if (!this.file) {
        this.error = "Must be selected"
        this.valid = false
      } else this.valid = true
      return !!this.file
    }
  },

  created() {
    this.form.register(this)
  }
}
</script>
