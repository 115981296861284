<template>
  <div>
    <span class="primary--text d-block mb-4">
      Upload additional Scans and Images.
    </span>
    <v-card flat>
      <v-card
        :color="dragOver ? 'card' : 'off'"
        outlined
        :light="dropBoxTextColor === 'black'"
        :dark="dropBoxTextColor === 'white'"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragOver = true"
        @dragenter.prevent="dragOver = true"
        @dragleave.prevent="dragOver = false"
        style="cursor: pointer"
        :class="{ pulsing: dragOver, 'rounded-10': true, 'mb-5': true }"
      >
        <v-card-text @click="addFile()">
          <v-row>
            <v-col class="text-center">
              <v-icon size="60" class="mt-5"> mdi-cloud-upload </v-icon>
            </v-col>
          </v-row>
          <v-row class="mb-5">
            <v-col class="text-center">
              <h3 v-if="!smallScreen" class="title-font">
                Drop your file(s) or folder here, or click to select them.
              </h3>
              <h3 v-if="smallScreen">Click to select your file(s).</h3>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="loading"
        max-width="600"
        persistent
        content-class="rounded-10"
      >
        <v-card color="off" class="rounded-10" v-if="loading">
          <v-card-text class="text-center pt-5">
            <v-row align="center" justify="center">
              <v-col cols="12" class="pa-5">
                <v-progress-circular
                  :rotate="-90"
                  :width="8"
                  :size="200"
                  :value="uploadProgress"
                  color="primary"
                >
                  <span class="primary--text">
                    <span class="loading-pulse">
                      Uploading Files...
                      <br />
                      {{ uploadProgress }}%
                    </span></span
                  >
                </v-progress-circular>
              </v-col>
            </v-row>
            <p :class="['mt-5', textColour]">
              Please wait for your uploads to finish before continuing.
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card class="elevation-0" v-if="existingUploads.length > 0">
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col
              cols="12"
              lg="6"
              xl="4"
              v-for="upload in existingUploads"
              :key="upload.uid"
            >
              <UploadCard :file="upload" @delete="deleteUpload(upload)" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
    <v-btn @click="$emit('next')" class="btn-primary"> Continue </v-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { getTextColour } from "@/utils"
import client from "@/lib/ApiClient"
import UploadCard from "./UploadFiles/UploadCard.vue"

export default {
  name: "UploadFiles",

  components: {
    UploadCard
  },
  data() {
    return {
      inputDOMElement: null,
      dragOver: false,
      files: [],
      failedUploads: [],
      uploadProgress: 0,
      loading: false
    }
  },

  computed: {
    ...mapGetters([
      "orderUid",
      "orderSessionUid",
      "orderDetails",
      "additionalFiles",
      "settings",
      "country"
    ]),

    textColour() {
      return `${getTextColour(this.settings.colours.off)}--text`
    },

    dropBoxTextColor() {
      if (this.dragOver) return getTextColour(this.settings.colours.card)
      else return getTextColour(this.settings.colours.off)
    },

    existingUploads() {
      return this.additionalFiles || []
    },

    smallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      )
    }
  },

  methods: {
    ...mapActions(["setAdditionalFiles"]),

    async onDrop(event) {
      await Promise.all(
        [...event.dataTransfer.items].map(async (item) => {
          const wkItem = item.webkitGetAsEntry()
          if (wkItem.isFile) {
            const fileItem = await new Promise((resolve) =>
              wkItem.file(resolve)
            )
            this.files.push(fileItem)
          } else if (wkItem.isDirectory) {
            await this.traverseDirectory(wkItem)
          }
        })
      )
      this.dragOver = false
      this.uploadFiles()
    },

    async traverseDirectory(wkItem) {
      const entries = await new Promise((resolve) =>
        wkItem.createReader().readEntries(resolve)
      )
      await Promise.all(
        entries.map(async (entry) => {
          if (entry.isFile) {
            const fileItem = await new Promise((resolve) => entry.file(resolve))
            this.files.push(fileItem)
          } else if (entry.isDirectory) {
            await this.traverseDirectory(entry)
          }
        })
      )
    },

    addFile() {
      if (!this.inputDOMElement) {
        this.inputDOMElement = document.createElement("input")
        this.inputDOMElement.type = "file"
        this.inputDOMElement.multiple = true
        this.inputDOMElement.addEventListener("change", (event) => {
          const array = [...event.target.files]
          array.forEach((entry) => {
            this.files.push(entry)
          })
          this.uploadFiles()
        })
      }
      this.inputDOMElement.click()
    },

    async uploadFiles() {
      if (!this.files.length) return
      this.loading = true
      try {
        const uploadedFiles = await client.oft.uploadFiles(
          this.files,
          (progressValue) => {
            this.uploadProgress = progressValue
          }
        )

        const payload = []

        uploadedFiles.forEach((file) => {
          if (file.uploaded) {
            payload.push({
              file_name: file.oftFileName,
              file_type: this.fileType(file.file),
              oft_file_uid: file.oftUid
            })
          } else this.failedUploads.push(file)
        })

        const addedFiles = await client.instance.orders.addFiles(
          this.orderUid,
          this.orderSessionUid,
          payload
        )

        await client.oft.getFileUrls(addedFiles.data)

        this.setAdditionalFiles([...this.additionalFiles, ...addedFiles.data])
      } finally {
        this.files = []
        this.uploadProgress = 0
        this.loading = false
      }
    },

    async deleteUpload(file) {
      const uploads = [...this.additionalFiles]
      const index = uploads.indexOf(file)
      if (index < 0) {
        return
      }
      uploads.splice(index, 1)
      this.setAdditionalFiles(uploads)
      client.instance.orders.deleteFile(
        this.orderUid,
        this.orderSessionUid,
        file.uid
      )
    },

    fileType(file) {
      if (!file?.name) return

      const fileExt = file.name.toLowerCase().split(".").pop()
      switch (fileExt) {
        case "jpg":
        case "jpeg":
        case "png":
          return "photo"
        case "ply":
        case "stl":
          return "scan"
        default:
          return "other"
      }
    }
  }
}
</script>
