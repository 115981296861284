import { render, staticRenderFns } from "./UploadCard.vue?vue&type=template&id=f34f02b4&scoped=true"
import script from "./UploadCard.vue?vue&type=script&lang=js"
export * from "./UploadCard.vue?vue&type=script&lang=js"
import style0 from "./UploadCard.vue?vue&type=style&index=0&id=f34f02b4&prod&scoped=true&lang=css"
import style1 from "./UploadCard.vue?vue&type=style&index=1&id=f34f02b4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f34f02b4",
  null
  
)

export default component.exports