<template>
  <v-btn
    width="100%"
    height="86"
    v-ripple
    outlined
    :color="dragOver ? 'primary' : ''"
    class="rounded-10 fill-height py-1 grey-border"
    style="cursor: pointer; user-select: none; background-color: #f5f5f5"
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragOver = true"
    @dragenter.prevent="dragOver = true"
    @dragleave.prevent="dragOver = false"
    @click="addFile()"
    :disabled="disabled"
  >
    <div class="d-flex flex-column">
      <span>
        <v-icon class="mr-1">$upload</v-icon>
        UPLOAD
      </span>
      <i style="font-size: 10px; text-transform: none">
        Click or Drag and Drop to upload
      </i>
      <i
        v-if="invalidFile || maxFilesExceeded"
        style="font-size: 10px; text-transform: none"
        class="error--text"
      >
        <span v-if="maxFilesExceeded"> Only one file can be uploaded. </span>
        <span v-if="invalidFile && fileExtension.length === 1">
          File type must be .{{ fileExtension[0] }}
        </span>
        <span v-if="invalidFile && fileExtension.length > 1">
          File type must be one of:
          <span v-for="(ext, index) in fileExtension" :key="index">
            <span v-if="index !== 0">, </span>.{{ ext }}
          </span>
        </span>
      </i>
    </div>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "FileUploader",

  props: ["text", "fileExtension", "value", "category", "disabled"],

  watch: {
    value(value) {
      if (value) {
        this.invalidFile = false
      }
    }
  },

  data() {
    return {
      dragOver: false,
      inputDOMElement: null,
      invalidFile: false,
      maxFilesExceeded: false
    }
  },

  computed: {
    ...mapGetters(["orderUid", "country"])
  },

  methods: {
    fileExtensionCheck(file) {
      let ext = file.name.toLowerCase().split(".").pop()
      return this.fileExtension.includes(ext)
    },

    fileType(file) {
      let ext = file.name.toLowerCase().split(".").pop()
      if (["stl", "ply"].includes(ext)) return "scan"
      if (["png", "jpg", "jpeg"].includes(ext)) return "photo"
      else return "other"
    },

    async addFile() {
      this.maxFilesExceeded = false
      this.invalidFile = false
      if (this.inputDOMElement) {
        this.inputDOMElement.value = ""
      } else {
        this.inputDOMElement = document.createElement("input")
        this.inputDOMElement.type = "file"
        this.inputDOMElement.multiple = false
        this.inputDOMElement.addEventListener("change", async (event) => {
          if (event.target.files.length > 1) {
            this.maxFilesExceeded = true
            return
          }

          const file = event.target.files[0]
          if (!this.fileExtensionCheck(file)) {
            this.invalidFile = true
            return
          }

          this.$emit("input", file)
        })
      }
      this.inputDOMElement.click()
    },

    async onDrop(event) {
      this.dragOver = false
      this.maxFilesExceeded = false
      this.invalidFile = false

      if (event.dataTransfer.files.length > 1) {
        this.maxFilesExceeded = true
        return
      }

      const file = event.dataTransfer.files[0]
      if (!this.fileExtensionCheck(file)) {
        this.invalidFile = true
        return
      }

      this.$emit("input", file)
    }
  }
}
</script>
